import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@op/opux";
import { useTranslation } from "react-i18next";
import queryClient from "../queryClient";

export const SignOutButton = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLogout = async (logoutType) => {
    if (logoutType === "popup") {
      await instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    queryClient.clear();
  };
  return (
    <div>
      <Button onClick={() => handleLogout("popup")}>
        {t("general.button_logout")}
      </Button>
      {/*<Button onClick={() => handleLogout("redirect")}>
        Sign out using Redirect
      </Button>*/}
    </div>
  );
};
