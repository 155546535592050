import { Honeybadger } from "@honeybadger-io/react";

const honeybadger = Honeybadger.configure({
  apiKey: "hbp_KyLweG6GWG2l1i5mEwOnf852SueQuh128PdT",
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled:
    process.env.REACT_APP_ENVIRONMENT &&
    process.env.REACT_APP_ENVIRONMENT !== "development",
  revision: process.env.REACT_APP_REVISION,
});

export default honeybadger;
