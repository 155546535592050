import { LogLevel } from "@azure/msal-browser";

// MSAL.js configuration parameters:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_AUTHORITY_ID}`,
    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.warn(
              `Unknown logging level ${level} with message ${message}`
            );
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

const scopes = ["openid", "User.Read"];

export const loginRequest = {
  scopes,
  redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
  // scopes: ["User.ReadBasic.All"],
  // scopes: ['https://oppalveluto365.sharepoint.com/Sites.Search.All'],
};

let sharepointScopes = [];

if (
  process.env.REACT_APP_ENVIRONMENT === "staging" ||
  process.env.REACT_APP_ENVIRONMENT === "production"
) {
  sharepointScopes.push(
    "https://oppalveluto365.sharepoint.com/Sites.Search.All"
  );
}

export const sharepointRequest = {
  scopes: sharepointScopes,
  redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
};
