import { useQuery, useMutation } from "react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated } from "@azure/msal-react";

import honeybadger from "../honeybadger";
import {
  getLocations,
  getServices,
  getGraphics,
  getCapacityObjects,
  getAllCapacityObjects,
  getMe,
  getMePhoto,
  getCheckAdminGroup,
  useGraphQueryFn,
  getReservations,
  getBuildings,
  getBuildingFloors,
  editService,
  useGraphToken,
  createReservation,
  createNewServiceGroup,
  deleteServiceFromGroup,
  editServiceGroup,
  removeServiceGroup,
  addServiceToGroup,
  removeReservation,
  getDesignatedLocations,
} from "../api";
import {
  getAdminBuildings,
  getAdminBuilding,
  getAdminBuildingFloors,
  getAdminLocation,
  postAdminLocation,
  getAdminLocationServices,
  patchAdminLocation,
  patchFloor,
  patchBuilding,
  postAdminBuildingCacheBusting,
} from "../api/admin";
import queryClient from "../queryClient";

/*
export const useReservation = () => {
  const { getAccessToken } = useGraphToken();

  const submitReservation = (payload) => {
    getAccessToken().then(async (accessToken) => {
      const data = await createReservation(accessToken, payload);
      console.log("data", data);
    });
  };

  return {
    submitReservation: submitReservation,
  };
};
*/

export const useServices = (selectedLocation) => {
  const { i18n } = useTranslation();

  const {
    data,
    isLoading: isLoadingLocations,
    error,
  } = useQuery(
    `${selectedLocation?.id}/services?locale=${i18n.language}`,
    useGraphQueryFn(getServices, selectedLocation?.id, i18n.language)
  );

  return {
    services: data?.data,
    error: data?.error,
    isLoading: isLoadingLocations,
  };
};

export const useAdminLocationServices = (locationId, locale, callback) => {
  const { getAccessToken } = useGraphToken();

  const {
    data,
    isLoading: isLoadingLocations,
    error,
    refetch: refetchServices,
  } = useQuery(
    ["admin", locationId, "services", locale],
    useGraphQueryFn(getAdminLocationServices, {
      locationId,
      locale,
    }),
    {
      enabled: !!locationId,
    }
  );

  const createServiceGroup = (payload, onSuccess, onError) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await createNewServiceGroup(
          accessToken,
          locationId,
          locale,
          payload
        );
        return request;
      })
      .then((response) => {
        callback();
        refetchServices();
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
        onError();
      });
  };

  const patchServiceGroup = (serviceGroupId, payload, onSuccess, onError) => {
    getAccessToken()
      .then(async (accessToken) => {
        const request = await editServiceGroup(
          accessToken,
          serviceGroupId,
          payload,
          locale
        );
        return request;
      })
      .then((_response) => {
        refetchServices();

        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
        onError(error);
      });
  };

  const deleteServiceGroup = (serviceGroupId) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await removeServiceGroup(accessToken, serviceGroupId);
        return request;
      })
      .then((response) => {
        refetchServices();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
      });
  };

  const addService = (serviceGroupId, payload, onSuccess) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await addServiceToGroup(
          accessToken,
          serviceGroupId,
          locale,
          payload
        );
        return request;
      })
      .then((_response) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
      });
  };

  const patchService = (serviceGroupId, payload, onSuccess) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await editService(
          accessToken,
          serviceGroupId,
          locale,
          payload
        );
        return request;
      })
      .then((_response) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
      });
  };

  const deleteService = (serviceId) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await deleteServiceFromGroup(accessToken, serviceId);
        return request;
      })
      .then((response) => {
        refetchServices();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
      });
  };

  return {
    services: data?.data,
    createServiceGroup,
    patchServiceGroup,
    deleteServiceGroup,
    addService,
    patchService,
    deleteService,
    error,
    isLoading: isLoadingLocations,
    refetchServices,
  };
};

export const useDesignatedLocations = (locationId, locale, { disabled }) => {
  const { data } = useQuery(
    [`designated_locations`, locationId, locale],
    useGraphQueryFn(getDesignatedLocations, { locationId, locale }),
    { enabled: !disabled }
  );

  return {
    designatedLocations: data?.data,
  };
};

export const useGraphics = (selectedFloor) => {
  const { data, isLoading, error } = useQuery(
    `floors/${selectedFloor}/graphics`,
    useGraphQueryFn(getGraphics, selectedFloor)
  );

  return {
    graphics: data?.data,
    error: error,
    isLoading,
  };
};

export const useCapacityObjects = (
  selectedFloor,
  startTime,
  endTime,
  usageType,
  designLocationId
) => {
  const baseUrl = `floors/${selectedFloor}/capacity_objects`;
  const params = `?floorId=${selectedFloor}&from=${startTime}&to=${endTime}&usageType=${usageType}&designated=${designLocationId}`;
  const url = baseUrl + params;
  const { data, isLoading, error } = useQuery(
    url,
    useGraphQueryFn(
      getCapacityObjects,
      selectedFloor,
      startTime,
      endTime,
      usageType,
      designLocationId
    ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  return {
    capacityObjects: data?.data,
    error: error,
    isLoading,
  };
};

export const useUser = ({ enabled = true } = {}) => {
  const { data: user, isFetching } = useQuery("me", useGraphQueryFn(getMe), {
    enabled,
  });
  return user;
};

const adminGroupId = process.env.REACT_APP_ADMIN_AD_GROUP_ID;
export const useAdminGroupMember = () => {
  const enabled = useIsAuthenticated();
  const { data } = useQuery(
    "checkAdminGroup",
    useGraphQueryFn(getCheckAdminGroup, { groupId: adminGroupId }),
    {
      enabled,
    }
  );
  if (data && data.value && data.value.includes(adminGroupId)) {
    return true;
  } else {
    return false;
  }
};

export const useReservations = ({
  onReservationSubmitSuccess = () => {},
  onReservationSubmitError = () => {},
  onReservationDeleteSuccess = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data,
    isLoading,
    refetch: refetchReservations,
  } = useQuery(`reservations`, useGraphQueryFn(getReservations));

  const { getAccessToken } = useGraphToken();

  const submitReservation = (payload) => {
    setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await createReservation(accessToken, payload);
        return request;
      })
      .then((response) => {
        if (response.data) {
          onReservationSubmitSuccess();
        } else if (response.error) {
          onReservationSubmitError(response.exception || response.error);
        }
        setIsSubmitting(false);
      });
  };

  const deleteReservation = (reservationId) => {
    setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await removeReservation(accessToken, reservationId);
        return request;
      })
      .then((response) => {
        onReservationDeleteSuccess();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
      });
  };

  return {
    reservations: data?.data,
    submitReservation: submitReservation,
    deleteReservation: deleteReservation,
    error: data?.error,
    isLoading,
    isSubmitting,
    refetchReservations,
  };
};

export const useLocationBuildings = (
  selectedLocation,
  usageType,
  designLocation
) => {
  const { data, isLoading, refetch } = useQuery(
    ["buildings", "desk", selectedLocation?.id, usageType, designLocation],
    useGraphQueryFn(getBuildings, {
      objectType: "desk",
      locationId: selectedLocation?.id,
      usageType,
      designated: designLocation,
    }),
    {
      enabled:
        !!selectedLocation && (usageType !== "designated" || !!designLocation),
    }
  );

  let buildings = data?.data;

  return {
    buildings: buildings,
    error: data?.error,
    isLoading,
    refetchBuildings: refetch,
  };
};

export const useAdminBuildings = () => {
  const { getAccessToken } = useGraphToken();

  const { data, isLoading, refetch } = useQuery(
    ["admin", "buildings"],
    useGraphQueryFn(getAdminBuildings, {})
  );

  let buildings = data?.data;

  return {
    buildings: buildings,

    error: data?.error,
    isLoading,
    refetchBuildings: refetch,
  };
};

export const useAdminBuilding = (buildingId) => {
  const { getAccessToken } = useGraphToken();

  const {
    data,
    isLoading,
    refetch: refetchBuilding,
  } = useQuery(
    ["admin", "buildings", buildingId],
    useGraphQueryFn(getAdminBuilding, { buildingId }),
    {
      enabled: !!buildingId,
    }
  );

  const buildingPatch = (buildingId, payload, onSuccess, onError) => {
    //setIsSubmitting(true);
    getAccessToken()
      .then(async (accessToken) => {
        const request = await patchBuilding(accessToken, buildingId, payload);
        return request;
      })
      .then((response) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
        onError();
      });
  };

  let building = data?.data;

  return {
    building,
    patchBuilding: buildingPatch,
    error: data?.error,
    isLoading,
    refetchBuilding,
  };
};

export const useLocations = () => {
  const { getAccessToken } = useGraphToken();

  const { data, isLoading, refetch } = useQuery(
    ["admin", "locations"],
    useGraphQueryFn(getLocations)
  );

  const createLocation = (payload, onSuccess, onError) => {
    getAccessToken()
      .then(async (accessToken) => {
        const request = await postAdminLocation(accessToken, payload);
        return request;
      })
      .then((response) => {
        onSuccess && onSuccess();
        refetch();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
        onError();
      });
  };

  return {
    locations: data?.data,
    refetchLocations: refetch,
    createLocation,
    error: data?.error,
    isLoadingLocations: isLoading,
  };
};

export const useAdminBuildingFloors = (buildingId) => {
  const { getAccessToken } = useGraphToken();

  const {
    data,
    isLoading,
    refetch: refetchFloors,
  } = useQuery(
    ["admin", buildingId, "floors"],
    useGraphQueryFn(getAdminBuildingFloors, {
      buildingId,
    }),
    {
      enabled: !!buildingId,
    }
  );

  /*
  const patchBuilding = (buildingId, payload, onSuccess, onError) => {
    //setIsSubmitting(true);
  };
  */

  const floorPatch = (floorId, payload, onSuccess, onError) => {
    getAccessToken()
      .then(async (accessToken) => {
        const request = await patchFloor(accessToken, floorId, payload);
        return request;
      })
      .then((response) => {
        refetchFloors();
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.warn(error);
        honeybadger.notify(error);
        onError();
      });
  };

  return {
    floors: data?.data,
    patchFloor: floorPatch,
    error: data?.error,
    isLoading,
  };
};

export const useAdminLocation = (locationId) => {
  const { data, isLoading } = useQuery(
    ["admin", "locations", locationId],
    useGraphQueryFn(getAdminLocation, { locationId }),
    {
      enabled: !!locationId,
    }
  );

  let location = data?.data;

  return {
    location,
    error: data?.error,
    isLoading,
  };
};

export const useAdminUpdateLocation = (succesCallback) => {
  const apiCall = useGraphQueryFn(patchAdminLocation);

  return useMutation(
    (updatedLocation) => {
      return apiCall(updatedLocation);
    },
    {
      onSuccess: (data) => {
        queryClient.resetQueries(["admin", "locations", data.id]);
        queryClient.invalidateQueries(["admin", "locations"]);
      },
    }
  );
};

export const useAdminCreateBuildingCacheBusting = () => {
  const apiCall = useGraphQueryFn(postAdminBuildingCacheBusting);

  return useMutation(
    (id) => {
      return apiCall({ id });
    },
    {
      onSuccess: ({ data }) => {
        queryClient.resetQueries(["admin", "buildings", data.id]);
        queryClient.invalidateQueries(["admin", "buildings"]);
      },
    }
  );
};

export const useFloors = (
  buildingId,
  usageType,
  designatedLocation,
  from,
  to
) => {
  const { data, isLoading: isLoadingLocations } = useQuery(
    ["floors", buildingId, usageType, designatedLocation, from, to],
    useGraphQueryFn(getBuildingFloors, {
      buildingId,
      usageType,
      designatedLocation,
      from: window.encodeURIComponent(from),
      to: window.encodeURIComponent(to),
    }),
    {
      enabled: !!buildingId,
    }
  );

  return {
    floors: data?.data,
    error: data?.error,
    isLoading: isLoadingLocations,
  };
};
