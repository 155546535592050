import { Select } from "@op/opux";
import { useTranslation } from "react-i18next";
import { currentLanguage } from "../i18n";

export default function LanguageSwitcher({ inline }) {
  const { t, i18n } = useTranslation();
  const language = currentLanguage();

  if (inline) {
    return (
      <a
        href="#change_language"
        style={{ position: "absolute", right: 15, top: 10 }}
        onClick={(event) => {
          event.preventDefault();

          if (language === "en") {
            i18n.changeLanguage("fi");
            localStorage.setItem("language", "fi");
          } else {
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");
          }
        }}
      >
        {language === "en"
          ? t("user_settings.languages.fi")
          : t("user_settings.languages.en")}
      </a>
    );
  }

  return (
    <Select
      onChange={(ev) => {
        const lang = ev.target.value;
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
      }}
    >
      <option value="fi" selected={language === "fi"}>
        {t("user_settings.languages.fi")}
      </option>
      <option value="en" selected={language === "en"}>
        {t("user_settings.languages.en")}
      </option>
    </Select>
  );
}
