import { Tile } from "@op/opux";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "./LanguageSwitcher";

export default function CaughtErrorView() {
  const { t } = useTranslation();

  return (
    <div className="App opds">
      <Tile
        style={{ borderRadius: 0, marginBottom: 0 }}
        className="top-title-tile"
      >
        <h1 className="text-center" id="top-title">
          {t("caught_error.something_went_wrong")}
        </h1>
      </Tile>
      <LanguageSwitcher inline />
      <div className="user-routes-container">
        <main id="main">
          <div className="app-side-padding margin-16 margin-bottom">
            <div className="margin-12">
              <p>{t("caught_error.reload_page")}</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
