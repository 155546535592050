import "./App.css";
import "@op/opux/components.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { LocationProvider } from "./utilities/LocationContext";
import Navigation from "./components/Navigation";
import TopTitleTile from "./components/TopTitleTile";
import { useTranslation } from "react-i18next";
import honeybadger from "./honeybadger";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";

const WorkSpaceBuildingPicker = lazy(() =>
  import("./components/WorkSpaceBuildingPicker/WorkSpaceBuildingPicker")
);
const FloorPlanViewer = lazy(() =>
  import("./components/FloorPlanViewer/FloorPlanViewer")
);
const Locations = lazy(() => import("./components/admin/Locations.js"));
const Buildings = lazy(() => import("./components/admin/Buildings.js"));
const SettingsView = lazy(() => import("./components/SettingsView.js"));
const ServicesView = lazy(() => import("./components/ServicesView.js"));
const NewsView = lazy(() => import("./components/NewsView.js"));
const WorkspaceView = lazy(() => import("./components/WorkspaceView.js"));
const ReserveWorkDeskMap = lazy(() =>
  import("./components/ReserveWorkDeskMap/ReserveWorkDeskMap.js")
);
const ReserveWorkspaceForm = lazy(() =>
  import("./components/ReserveWorkspaceForm.js")
);

export const SessionControls = ({}) => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? <SignOutButton /> : <SignInButton />;
};

function App() {
  const { i18n } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts[0]) {
      honeybadger.setContext({
        user_id: accounts[0].localAccountId,
      });
    }
  }, [accounts]);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    i18n.changeLanguage(lang);
  }, [i18n]);

  return (
    <div className="App opds">
      {!isAuthenticated && (
        <UnauthenticatedTemplate>
          <SessionControls />
        </UnauthenticatedTemplate>
      )}

      <AuthenticatedTemplate>
        {!isAuthenticated && <SessionControls />}

        <Router>
          <Suspense fallback={<TopTitleTile />}>
            <main id="main" style={{ position: "relative" }}>
              <Switch>
                <Route path="/admin">
                  <div style={{ position: "relative" }}>
                    <Navigation />
                    <Route path="/admin/buildings">
                      <Buildings />
                    </Route>

                    <Route path="/admin/locations">
                      <Locations />
                    </Route>

                    <Route exact path="/admin">
                      <Redirect to="/admin/buildings" />
                    </Route>
                  </div>
                </Route>
                <>
                  <TopTitleTile />

                  <LocationProvider>
                    <Route exact path="/">
                      <div className="user-routes-container">
                        <Redirect to="/workspace" />
                      </div>
                    </Route>

                    <Route exact path="/work_space_building_picker">
                      <div className="user-routes-container">
                        <WorkSpaceBuildingPicker />
                      </div>
                    </Route>

                    <Route exact path="/reserve_workspace">
                      <div className="user-routes-container">
                        <ReserveWorkspaceForm />
                      </div>
                    </Route>

                    <Route exact path="/workspace">
                      <div className="user-routes-container">
                        <WorkspaceView />
                      </div>
                    </Route>
                    <Route exact path="/news">
                      <div className="user-routes-container">
                        <NewsView />
                      </div>
                    </Route>
                    <Route exact path="/services">
                      <div className="user-routes-container">
                        <ServicesView />
                      </div>
                    </Route>
                    <Route exact path="/settings">
                      <div className="user-routes-container">
                        <SettingsView />
                      </div>
                    </Route>
                  </LocationProvider>

                  <Route exact path="/floor_map">
                    <ReserveWorkDeskMap />
                  </Route>

                  <Route exact path="/floor_plan_viewer">
                    <FloorPlanViewer />
                  </Route>

                  <Switch>
                    <Route path="/admin" />
                    <Route>
                      <Navigation />
                    </Route>
                  </Switch>
                </>
              </Switch>
            </main>
          </Suspense>
        </Router>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
