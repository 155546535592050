import { useState, createContext, useMemo } from "react";
import { useUser, useLocations } from "../utilities/hooks";
import { useEffect, useCallback } from "react";
const initialLocation = localStorage.getItem("location");

export const LocationContext = createContext(null);

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState();
  // Actions for changing state
  const { locations } = useLocations();

  useEffect(() => {
    if (locations && locations[0] && !location) {
      const newLocation =
        initialLocation &&
        locations.find(
          (candidate) => candidate.id.toString() === initialLocation.toString()
        );
      if (newLocation) {
        setLocation(newLocation);
      } else {
        // Set first location as the location if none is set
        localStorage.setItem("location", locations[0].id);
        setLocation(locations[0]);
      }
    }

    // if (!initialLocation && locations && locations[0]) {
    //   localStorage.setItem("location", locations[0].id);
    //   setLocation(locations[0]);
    // }
  }, [location, locations]);

  const setSelectedLocation = useCallback((newLocation) => {
    localStorage.setItem("location", newLocation.id);
    setLocation(newLocation);
  }, []);

  const context = useMemo(() => {
    return {
      selectedLocation: location,
      setSelectedLocation,
    };
  }, [location, setSelectedLocation]);

  return (
    <LocationContext.Provider value={context}>
      {children}
    </LocationContext.Provider>
  );
};
