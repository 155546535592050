import { Tile } from "@op/opux";
import { useEffect, useState } from "react";
import "./TopTitleTile.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TopTitleTile = ({ }) => {
  const [title, setTitle] = useState("");
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let title;
    const path = location.pathname;
    switch (path) {
      case "/settings":
        title = t("general.user_settings_title");
        break;
      case "/services":
        title = t("general.services_tab_title");
        break;
      case "/workspace":
        title = t("general.workspace_tab_title");
        break;
      case "/news":
        title = t("general.news_tab_title");
        break;
      case "/reserve_workspace":
        title = t("general.workspace_tab_title");
        break;
      case "/floor_map":
        title = t("general.workspace_tab_title");
        break;
      case "/work_space_building_picker":
        title = t("view_floors.real_time_status");
        break;
      case "/floor_plan_viewer":
        title = t("view_floors.real_time_status");
        break;

      default:
    }
    setTitle(title);
  }, [t, location, i18n.language]);

  return (
    <Tile
      style={{ borderRadius: 0, marginBottom: 0 }}
      className="top-title-tile"
    >
      <h1 className="text-center" id="top-title">
        {title}
      </h1>
    </Tile>
  );
};

export default TopTitleTile;
