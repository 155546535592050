import { Tile } from "@op/opux";
import { IconHome } from "@op/opux-icons/insurance";
import { IconCalendar, IconAdmin } from "@op/opux-icons/misc";
import { IconSettings } from "@op/opux-icons/action";
import { ReactComponent as Badge } from "../icons/badge.svg";

import { useTranslation } from "react-i18next";

import { Switch, Route, Link } from "react-router-dom";
import { useAdminGroupMember } from "../utilities/hooks";

import "./Navigation.css";

const Navigation = () => {
  const isAdmin = useAdminGroupMember();

  return (
    <Switch>
      <Route
        path="/admin"
        children={() => {
          if (!isAdmin) {
            return null;
          }

          return (
            <Tile
              title={"Korttelisovellus Admin"}
              className={"navigation-admin-tile"}
            >
              <AdminNavigation />
            </Tile>
          );
        }}
      />
      <Route path="/">
        <div className={"navigation-user-tile"}>
          <UserNavigation />
        </div>
      </Route>
    </Switch>
  );
};

const UserNavigation = () => {
  const { t } = useTranslation();
  const isAdmin = useAdminGroupMember();

  let navItems = [
    {
      icon: <IconCalendar className="navigation-icon" />,
      label: t("general.workspace_tab_title"),
      to: "/workspace",
    },
    {
      icon: <IconHome className="navigation-icon" />,
      label: t("general.news_tab_title"),
      to: "/news",
    },
    {
      icon: <Badge className="navigation-icon" />,
      label: t("general.services_tab_title"),
      to: "/services",
    },
    {
      icon: <IconSettings className="navigation-icon" />,
      label: t("general.user_settings_title"),
      to: "/settings",
    },
  ];

  if (isAdmin) {
    navItems.push({
      icon: <IconAdmin className="navigation-icon" />,
      label: t("general.admin_title"),
      to: "/admin",
    });
  }

  return (
    <div className="navigation-user-navitems-container">
      {navItems.map((navItem) => {
        return (
          <Link
            to={navItem.to}
            key={navItem.label}
            className="navigation-link"
          >
            <div>{navItem.icon}</div>
            <small className="nav-item-label">{navItem.label}</small>
          </Link>
        );
      })}
    </div>
  );
};

const AdminNavigation = () => {
  return (
    <>
      <div className="navigation-admin-tile-main-navigation">
        <div className="inline-block">
          <Link to="/admin/buildings" className="ds-action ds-action--inline">
            Rakennukset
          </Link>
        </div>
        <div className="inline-block margin-20 margin-left">
          <Link to="/admin/locations" className="ds-action ds-action--inline">
            Lokaatiot
          </Link>
        </div>
      </div>

      <div className="navigation-admin-tile-secondary-navigation">
        <div className="inline-block margin-20 margin-left">
          <Link to="/" className="ds-action ds-action--inline">
            Pois administa
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navigation;
