import honeybadger from "../honeybadger";

export function getAdminBuildings(accessToken) {
  const url = "buildings";
  return get(accessToken, url);
}

export function getAdminBuilding(accessToken, { buildingId }) {
  const url = `buildings/${buildingId}`;
  return get(accessToken, url);
}

export function getAdminBuildingFloors(accessToken, { buildingId }) {
  const url = `buildings/${buildingId}/floors?all=true`;
  return get(accessToken, url);
}

export function getAdminLocation(accessToken, { locationId }) {
  const url = `locations/${locationId}`;
  return get(accessToken, url);
}

export function getAdminLocationServices(accessToken, { locationId, locale }) {
  const url = `locations/${locationId}/services?locale=${locale}`;
  return get(accessToken, url);
}

export function patchAdminLocation(accessToken, { id, ...attributes }) {
  const url = `locations/${id}`;
  return patch(accessToken, url, { data: attributes });
}

export function postAdminLocation(accessToken, { id, ...attributes }) {
  const url = `locations`;
  return post(accessToken, url, { data: attributes });
}

export function postAdminBuildingCacheBusting(accessToken, { id }) {
  return post(accessToken, `buildings/${id}/cache_busting`);
}

export function patchFloor(accessToken, floorId, payload) {
  return put(accessToken, `floors/${floorId}`, payload);
}

export function patchBuilding(accessToken, buildingId, payload) {
  return patch(accessToken, `buildings/${buildingId}`, payload);
}

function get(accessToken, url, onSuccess, onError) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => response.json())
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function post(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function put(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function patch(accessToken, url, payload) {
  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  });

  const options = {
    method: "PATCH",
    headers: headers,
    mode: "cors",
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function remove(accessToken, url, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "DELETE",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function getBlob(accessToken, url) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(apiUrl(url), options)
    .then((response) => response.blob())
    .catch((error) => {
      honeybadger.notify(error);
      throw error;
    });
}

function apiUrl(path) {
  return `${process.env.REACT_APP_API_HOST}/api/${path}`;
}
